<template>
  <div class="bool-filter">
    <p class="bool-filter__text">
      {{ filter?.name }}
    </p>
    <div class="bool-filter__checkboxes">
      <RadioGroup
        :model-value="internalFilter?.value"
        class="mm-radio-filter__options"
        column
        :buttons="radioGroupButtons"
        is-disable-emit-value-by-btn-list-change
        @update:model-value="onSelectValue"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IFilterBoolean } from 'models/filters.model';
import { IFilterRadio } from 'shared/models/filters.model';
import RadioGroup from '../RadioGroup.vue';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';
import { IRadioButton } from '@src/entries/types';
import { ref } from 'vue';

const props = defineProps<{
  filter: IFilterBoolean;
  modelValue: IFilterBoolean;
  showCount?: boolean;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', newValue: IFilterBoolean): void;
}>();

const watchSubscription = new WatchSubscription();

const radioGroupButtons: Array<IRadioButton> = [
  {
    value: 'Nomatter',
    label: 'Не важно',
  },
  {
    value: 'Yes',
    label: 'Да',
  },
  {
    value: 'No',
    label: 'Нет',
  },
];

const options = [
  {
    id: 'Nomatter',
    name: 'Не важно',
  },
  {
    id: 'Yes',
    name: 'Да',
  },
  {
    id: 'No',
    name: 'Нет',
  },
];

const internalFilter = ref<IFilterRadio>();

function setInternalFilter(newFilter: IFilterRadio): void {
  internalFilter.value = newFilter;
}

function onSelectValue(value: IFilterRadio['value']): void {
  internalFilter.value.value = value;
  internalFilter.value.options = options;
  emits('update:modelValue', internalFilter.value);
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    (newModelValue) => setInternalFilter(newModelValue || props.filter),
  ),
);

onMounted(() => setInternalFilter(props.modelValue || props.filter));

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
.filter__list__item {
  :deep(span.content),
  :deep(span.light) {
    display: inline-block;

    &::first-line {
      line-height: 24px;
    }
  }
}

.bool-filter {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__text {
    @include text-m16;
    color: $color-text-main;
    margin-bottom: 0;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
